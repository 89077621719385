
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { OptionCategory } from '@alao-frontend/core'

@Component
export default class OfferOptionsItemIcon extends Vue {
  @Prop({
    required: true,
    type: String,
  })
  readonly category!: OptionCategory

  @Prop({
    required: true,
    type: String,
  })
  readonly color!: string

  get iconOptions () {
    switch (this.category) {
      case 'calls':
        return {
          icon: require('~/assets/icons/offer-details/icon-calls.svg?inline'),
          width: 24,
          height: 24,
        } as const
      case 'calls-outside':
        return {
          icon: require('~/assets/icons/offer-details/icon-calls-outside.svg?inline'),
          width: 24,
          height: 24,
        } as const
      case 'data':
        return {
          icon: require('~/assets/icons/offer-details/icon-data.svg?inline'),
          width: 24,
          height: 24,
        } as const
      case 'data-roaming':
        return {
          icon: require('~/assets/icons/offer-details/icon-data-roaming.svg?inline'),
          width: 24,
          height: 24,
        } as const
      case 'internet':
        return {
          icon: require('~/assets/icons/offer-details/icon-internet.svg?inline'),
          width: 24,
          height: 24,
        } as const
      case 'tv':
        return {
          icon: require('~/assets/icons/offer-details/icon-tv.svg?inline'),
          width: 24,
          height: 24,
        } as const
      case 'netflix':
        return {
          icon: require('~/assets/icons/offer-details/icon-netflix.svg?inline'),
          width: 24,
          height: 24,
        } as const
      case 'network':
        return {
          icon: require('~/assets/icons/offer-details/icon-network.svg?inline'),
          width: 24,
          height: 24,
        } as const
      case 'contract-duration':
        return {
          icon: require('~/assets/icons/offer-details/icon-contract-length.svg?inline'),
          width: 24,
          height: 24,
        } as const
      case 'mostProfitableVoucher':
        return {
          icon: require('~/assets/icons/offer-details/icon-gift.svg?inline'),
          width: 24,
          height: 24,
        } as const
      case 'customerService':
        return {
          icon: require('~/assets/icons/offer-details/icon-gift.svg?inline'),
          width: 24,
          height: 24,
        } as const
    }
  }
}
