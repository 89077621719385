
import { Component, namespace, Prop, Vue } from 'nuxt-property-decorator'
import { OfferCategoryType } from '@alao-frontend/core'
import {
  GetterType as PromoCampaignGetterType,
  namespace as promoCampaignNamespace,
  PublicGetters as PromoCampaignPublicGetters,
} from '~/store/modules/PromoCampaign'
import OfferDynamicBanner from '~/components/banners/OfferDynamicBanner'

const PromoCampaignNamespace = namespace(promoCampaignNamespace)

@Component({
  components: {
    OfferDynamicBanner,
  },
})
export default class OfferPromoBanners extends Vue {
  @Prop({ type: Number, required: true })
  readonly id!: number

  @Prop({ type: String, required: true })
  readonly offerType!: OfferCategoryType

  @PromoCampaignNamespace.Getter(PromoCampaignGetterType.GET_OFFER_BANNERS)
  readonly getOfferBanners!: PromoCampaignPublicGetters[PromoCampaignGetterType.GET_OFFER_BANNERS]

  @PromoCampaignNamespace.Getter(PromoCampaignGetterType.GET_OFFER_BANNER_TEMPLATE)
  readonly getOfferBannerTemplate!: PromoCampaignPublicGetters[PromoCampaignGetterType.GET_OFFER_BANNER_TEMPLATE]

  get offerBanners () {
    return this.getOfferBanners(this.offerType, this.id.toString())
  }

  getBannerTemplate (templateId: string) {
    return this.getOfferBannerTemplate(templateId)
  }
}
