import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { OfferBanner } from '~/types/promo/offer-banner'
import type { OfferBannerTemplate } from '~/types/promo/offer-banner-template'

@Component
export default class OfferDynamicBannerMixin extends Vue {
  @Prop({
    required: true,
    default: () => ({}),
    type: Object,
  })
  readonly banner!: OfferBanner

  @Prop({
    required: true,
    default: () => ({}),
    type: Object,
  })
  readonly template!: OfferBannerTemplate

  get bannerBodyStyles () {
    return {
      backgroundColor: this.template.backgroundColor,
      color: this.template.textColor,
    }
  }

  get content () {
    switch (this.$i18n.locale) {
      case 'en':
        return this.banner.contentEN

      case 'de':
        return this.banner.contentDE

      case 'fr':
        return this.banner.contentFR

      default:
        return this.banner.contentEN
    }
  }
}
