
import { Component } from 'nuxt-property-decorator'
import OfferDynamicBannerTemplate1 from '~/components/banners/OfferDynamicBanner/templates/OfferDynamicBannerTemplate1'
import OfferDynamicBannerMixin from '~/components/banners/OfferDynamicBanner/mixins/offer-dynamic-banner.mixin'

@Component({
  components: {
    OfferDynamicBannerTemplate1,
  },
})
export default class OfferDynamicBanner extends OfferDynamicBannerMixin {
  // ATM we have only one banner template
  // Further another banners could be added
  get component () {
    return 'OfferDynamicBannerTemplate1'
  }
}
