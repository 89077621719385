
import { Component, Vue } from 'nuxt-property-decorator'
import AppTooltip from '~/components/AppTooltip.vue'

@Component({
  components: {
    AppTooltip,
  },
})
export default class BenefitTooltip extends Vue {}
