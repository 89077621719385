
import { Component } from 'nuxt-property-decorator'
import OfferDynamicBannerMixin from '~/components/banners/OfferDynamicBanner/mixins/offer-dynamic-banner.mixin'

@Component
export default class OfferDynamicBannerTemplate1 extends OfferDynamicBannerMixin {
  get imageAlignment () {
    return this.template.alignImage
  }
}
